// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-layers-MapFeaturePopup-module__popup {
    background-color: white;
    font-size: small;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/layers/MapFeaturePopup.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,wCAAwC;IACxC,0BAA0B;IAC1B,mBAAmB;AACvB","sourcesContent":[".popup {\r\n    background-color: white;\r\n    font-size: small;\r\n    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);\r\n    padding: 5px 10px 5px 10px;\r\n    border-radius: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `src-layers-MapFeaturePopup-module__popup`
};
export default ___CSS_LOADER_EXPORT___;
