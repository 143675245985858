// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-sidebar-search-PopUp-module__popup {
    box-sizing: border-box;
    background-color: white;
    border: solid 1px lightgray;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    position: absolute;
    pointer-events: all;
}
`, "",{"version":3,"sources":["webpack://./src/sidebar/search/PopUp.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,2BAA2B;IAC3B,0CAA0C;IAC1C,qBAAqB;IACrB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".popup {\r\n    box-sizing: border-box;\r\n    background-color: white;\r\n    border: solid 1px lightgray;\r\n    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);\r\n    border-radius: 0.2rem;\r\n    position: absolute;\r\n    pointer-events: all;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `src-sidebar-search-PopUp-module__popup`
};
export default ___CSS_LOADER_EXPORT___;
