import { useState, useRef } from "react";
// import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { AudioRecorder } from 'react-audio-voice-recorder';
import { saveAs } from 'file-saver';
import './style.css'
import axios from 'axios'
import fs from 'fs';

const blobToFile = (theBlob: Blob, fileName:string): File => {
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;
    
  //Cast to a File() type
  return theBlob as File;
}

// https://stackblitz.com/edit/react-ts-ryj6jz?file=App.tsx
const AudioRecorderComponent = ({addType, callback }: {addType: string, callback: (input: string) => void}): any => {
  // const recorderControls = useAudioRecorder(
  //   {
  //     noiseSuppression: true,
  //     echoCancellation: true,
  //   },
  //   (err:any) => console.table(err) // onNotAllowedOrFound
  // );
  const addAudioElement = async (blob:any) => {
    let data = new FormData();
    const myFile = new File([blob], 'audiofile.mp3', {
      type: blob.type,
    });
    data.append("file", myFile);
    // await saveAs(blob, path);

    let config = {
      method: "post",
      headers: {
        "content-type": "multipart/form-data",
      },
      // url: `http://192.168.0.186:8000/convert?audio=${path}`,
      // url: `http://192.168.0.186:8000/upload-audio`,
      url: 'https://demo18-v2t.ai.bnksolution.com/upload-audio/',
      data: data,
    };
  
    try {
      const response = await axios.request(config);
      const data = response.data;
      console.log(addType, data.text);
      callback(data.text)
      return { data };
    } catch (error) {
      return {};
    }


  };
    return (
      <div className="audio-recorder-container">
        <AudioRecorder
          onRecordingComplete={(blob: any) => addAudioElement(blob)}
          // recorderControls={recorderControls}
          // downloadOnSavePress={true}
          // downloadFileExtension="mp3"
          // showVisualizer={true}
        />
      </div>
    );
};
export default AudioRecorderComponent;