// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pathDetails-PathDetails-module__heightgraphContainer {
    position: relative;
    /* display: flex; */
    display: none;
    flex-direction: column;
    align-items: flex-end;
}

.src-pathDetails-PathDetails-module__innerDiv {
    position: relative;
    pointer-events: auto;
    background-color: white;
    border-radius: 0.4rem 0 0 0;
}
`, "",{"version":3,"sources":["webpack://./src/pathDetails/PathDetails.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,uBAAuB;IACvB,2BAA2B;AAC/B","sourcesContent":[".heightgraphContainer {\r\n    position: relative;\r\n    /* display: flex; */\r\n    display: none;\r\n    flex-direction: column;\r\n    align-items: flex-end;\r\n}\r\n\r\n.innerDiv {\r\n    position: relative;\r\n    pointer-events: auto;\r\n    background-color: white;\r\n    border-radius: 0.4rem 0 0 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heightgraphContainer": `src-pathDetails-PathDetails-module__heightgraphContainer`,
	"innerDiv": `src-pathDetails-PathDetails-module__innerDiv`
};
export default ___CSS_LOADER_EXPORT___;
