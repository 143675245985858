// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-map-MapOptions-module__mapOptionsContainer {
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 10px;
    display: flex;
}

.src-map-MapOptions-module__options {
    width: 220px;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
}

.src-map-MapOptions-module__option {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

.src-map-MapOptions-module__layerButton {
    margin: 5px !important;
    width: 32px;
    height: 32px;
}

.src-map-MapOptions-module__layerButton svg {
    color: gray;
    height: 100%;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/map/MapOptions.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;AACf","sourcesContent":[".mapOptionsContainer {\r\n    position: absolute;\r\n    z-index: 2;\r\n    top: 10px;\r\n    right: 10px;\r\n    display: flex;\r\n}\r\n\r\n.options {\r\n    width: 220px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    margin: 0.5rem;\r\n}\r\n\r\n.option {\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 0.5rem;\r\n    align-items: center;\r\n}\r\n\r\n.layerButton {\r\n    margin: 5px !important;\r\n    width: 32px;\r\n    height: 32px;\r\n}\r\n\r\n.layerButton svg {\r\n    color: gray;\r\n    height: 100%;\r\n    width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapOptionsContainer": `src-map-MapOptions-module__mapOptionsContainer`,
	"options": `src-map-MapOptions-module__options`,
	"option": `src-map-MapOptions-module__option`,
	"layerButton": `src-map-MapOptions-module__layerButton`
};
export default ___CSS_LOADER_EXPORT___;
