// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-map-Map-module__contextMenu {
    z-index: 3;
}

.src-map-Map-module__mapContainer {
    height: 100%;
    width: 100%;
}

.src-map-Map-module__customZoom {
    right: 17px;
    top: 115px;
}

@media (max-width: 44rem) {
    .src-map-Map-module__customZoom {
        top: unset;
        bottom: 0.5em;
    }
}

/* changing the position of the attribution is a bit complex as we have to repeat the other css */
.src-map-Map-module__customAttribution {
    left: 0;
    bottom: 0;
    padding: 1px 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0 4px 0 0;
}

.src-map-Map-module__customAttribution ul {
    font-size: 12px;
}

.src-map-Map-module__customAttribution a {
    padding-right: 3px;
    color: rgba(0, 60, 136, 0.7);
    text-decoration: none;
}

.src-map-Map-module__customAttribution button {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/map/Map.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,WAAW;IACX,UAAU;AACd;;AAEA;IACI;QACI,UAAU;QACV,aAAa;IACjB;AACJ;;AAEA,iGAAiG;AACjG;IACI,OAAO;IACP,SAAS;IACT,gBAAgB;IAChB,0CAA0C;IAC1C,wBAAwB;AAC5B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,4BAA4B;IAC5B,qBAAqB;AACzB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".contextMenu {\r\n    z-index: 3;\r\n}\r\n\r\n.mapContainer {\r\n    height: 100%;\r\n    width: 100%;\r\n}\r\n\r\n.customZoom {\r\n    right: 17px;\r\n    top: 115px;\r\n}\r\n\r\n@media (max-width: 44rem) {\r\n    .customZoom {\r\n        top: unset;\r\n        bottom: 0.5em;\r\n    }\r\n}\r\n\r\n/* changing the position of the attribution is a bit complex as we have to repeat the other css */\r\n.customAttribution {\r\n    left: 0;\r\n    bottom: 0;\r\n    padding: 1px 5px;\r\n    background-color: rgba(255, 255, 255, 0.8);\r\n    border-radius: 0 4px 0 0;\r\n}\r\n\r\n.customAttribution ul {\r\n    font-size: 12px;\r\n}\r\n\r\n.customAttribution a {\r\n    padding-right: 3px;\r\n    color: rgba(0, 60, 136, 0.7);\r\n    text-decoration: none;\r\n}\r\n\r\n.customAttribution button {\r\n    display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contextMenu": `src-map-Map-module__contextMenu`,
	"mapContainer": `src-map-Map-module__mapContainer`,
	"customZoom": `src-map-Map-module__customZoom`,
	"customAttribution": `src-map-Map-module__customAttribution`
};
export default ___CSS_LOADER_EXPORT___;
