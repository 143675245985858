// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-map-ContextMenuContent-module__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 11em;
}

button.src-map-ContextMenuContent-module__entry div {
    margin-bottom: 2px;
    padding-right: 8px;
}

.src-map-ContextMenuContent-module__entry {
    display: flex;
    align-items: center;

    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    outline: inherit;
    font-size: 16px;
    padding: 0.15em 0.8em;
}

.src-map-ContextMenuContent-module__entry:hover {
    background-color: #ececec;
}

.src-map-ContextMenuContent-module__entry:active {
    background-color: #c6c6c6;
}

.src-map-ContextMenuContent-module__entry:disabled {
    color: lightgray;
}
`, "",{"version":3,"sources":["webpack://./src/map/ContextMenuContent.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    max-width: 11em;\r\n}\r\n\r\nbutton.entry div {\r\n    margin-bottom: 2px;\r\n    padding-right: 8px;\r\n}\r\n\r\n.entry {\r\n    display: flex;\r\n    align-items: center;\r\n\r\n    border: none;\r\n    background: none;\r\n    text-align: left;\r\n    cursor: pointer;\r\n    outline: inherit;\r\n    font-size: 16px;\r\n    padding: 0.15em 0.8em;\r\n}\r\n\r\n.entry:hover {\r\n    background-color: #ececec;\r\n}\r\n\r\n.entry:active {\r\n    background-color: #c6c6c6;\r\n}\r\n\r\n.entry:disabled {\r\n    color: lightgray;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `src-map-ContextMenuContent-module__wrapper`,
	"entry": `src-map-ContextMenuContent-module__entry`
};
export default ___CSS_LOADER_EXPORT___;
