// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* todo: this was just copy-pasted from https://openlayers.org/en/latest/examples/popup.html.
   the popup looks ugly still, but at the very minimum the background color needs to be set to make it visible.
   the popup design needs an overhaul anyway... https://github.com/graphhopper/graphhopper-maps/issues/136
   */
.src-layers-DefaultMapPopup-module__popup {
    position: absolute;
    bottom: 12px;
    left: -50px;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 0 15px 0 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    font-size: small;
    width: 110px;
}
.src-layers-DefaultMapPopup-module__popup:after,
.src-layers-DefaultMapPopup-module__popup:before {
    top: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.src-layers-DefaultMapPopup-module__popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}
.src-layers-DefaultMapPopup-module__popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}
`, "",{"version":3,"sources":["webpack://./src/layers/DefaultMapPopup.module.css"],"names":[],"mappings":"AAAA;;;IAGI;AACJ;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,wCAAwC;IACxC,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;AAChB;AACA;;IAEI,SAAS;IACT,yBAAyB;IACzB,YAAY;IACZ,SAAS;IACT,QAAQ;IACR,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,UAAU;IACV,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,UAAU;IACV,kBAAkB;AACtB","sourcesContent":["/* todo: this was just copy-pasted from https://openlayers.org/en/latest/examples/popup.html.\r\n   the popup looks ugly still, but at the very minimum the background color needs to be set to make it visible.\r\n   the popup design needs an overhaul anyway... https://github.com/graphhopper/graphhopper-maps/issues/136\r\n   */\r\n.popup {\r\n    position: absolute;\r\n    bottom: 12px;\r\n    left: -50px;\r\n    background-color: white;\r\n    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);\r\n    padding: 0 15px 0 15px;\r\n    border-radius: 10px;\r\n    border: 1px solid #cccccc;\r\n    font-size: small;\r\n    width: 110px;\r\n}\r\n.popup:after,\r\n.popup:before {\r\n    top: 100%;\r\n    border: solid transparent;\r\n    content: ' ';\r\n    height: 0;\r\n    width: 0;\r\n    position: absolute;\r\n    pointer-events: none;\r\n}\r\n.popup:after {\r\n    border-top-color: white;\r\n    border-width: 10px;\r\n    left: 48px;\r\n    margin-left: -10px;\r\n}\r\n.popup:before {\r\n    border-top-color: #cccccc;\r\n    border-width: 11px;\r\n    left: 48px;\r\n    margin-left: -11px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `src-layers-DefaultMapPopup-module__popup`
};
export default ___CSS_LOADER_EXPORT___;
