// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-PlainButton-module__button {
    outline-color: #368fe8; /* doesn't work for firefox? force blue for chrome too */
    border: none;
    padding: 0;
    margin: 0;
    background-color: inherit;
    color: #5b616a;
    font-size: 1rem;
}

.src-PlainButton-module__button:hover {
    cursor: pointer;
    color: #2a2b31;
}

.src-PlainButton-module__button:active {
    color: lightgray;
}
`, "",{"version":3,"sources":["webpack://./src/PlainButton.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB,EAAE,wDAAwD;IAChF,YAAY;IACZ,UAAU;IACV,SAAS;IACT,yBAAyB;IACzB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".button {\r\n    outline-color: #368fe8; /* doesn't work for firefox? force blue for chrome too */\r\n    border: none;\r\n    padding: 0;\r\n    margin: 0;\r\n    background-color: inherit;\r\n    color: #5b616a;\r\n    font-size: 1rem;\r\n}\r\n\r\n.button:hover {\r\n    cursor: pointer;\r\n    color: #2a2b31;\r\n}\r\n\r\n.button:active {\r\n    color: lightgray;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `src-PlainButton-module__button`
};
export default ___CSS_LOADER_EXPORT___;
