// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cm-s-default .cm-string {
    color: #067d17;
}

.cm-s-default .cm-property {
    color: #871094;
}

.cm-s-default .cm-number {
    color: #1750eb;
}

.cm-s-default .cm-atom {
    color: #0033b3;
}
`, "",{"version":3,"sources":["webpack://./src/sidebar/CustomModelBox.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".cm-s-default .cm-string {\r\n    color: #067d17;\r\n}\r\n\r\n.cm-s-default .cm-property {\r\n    color: #871094;\r\n}\r\n\r\n.cm-s-default .cm-number {\r\n    color: #1750eb;\r\n}\r\n\r\n.cm-s-default .cm-atom {\r\n    color: #0033b3;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
