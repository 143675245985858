// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-map-LocationButton-module__locationOnOff {
    position: absolute;
    right: 10px;
    top: 62px;
    background-color: red;

    padding: 4px;
    width: 34px;
    height: 34px;
}

.src-map-LocationButton-module__locationOnOff svg {
    color: gray;
    width: 100%;
    height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/map/LocationButton.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,qBAAqB;;IAErB,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,YAAY;AAChB","sourcesContent":[".locationOnOff {\r\n    position: absolute;\r\n    right: 10px;\r\n    top: 62px;\r\n    background-color: red;\r\n\r\n    padding: 4px;\r\n    width: 34px;\r\n    height: 34px;\r\n}\r\n\r\n.locationOnOff svg {\r\n    color: gray;\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"locationOnOff": `src-map-LocationButton-module__locationOnOff`
};
export default ___CSS_LOADER_EXPORT___;
