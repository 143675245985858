import { Map } from 'ol'
import { useEffect } from 'react'
import { PathDetailsStoreState } from '@/stores/PathDetailsStore'
import { Coordinate } from '@/stores/QueryStore'
import { FeatureCollection } from 'geojson'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Stroke, Style } from 'ol/style'
import { GeoJSON } from 'ol/format'
import { fromLonLat } from 'ol/proj'
import { getQueryStore, getRouteStore } from '@/stores/Stores'
import { getApi } from '@/api/Api'

const highlightedPathSegmentLayerKey = 'highlightedPathSegmentLayer'



export const getMaxWidthList = (profileData: any) => {
    let profiles = profileData?.config?.profiles || [];
    let maxWidthList = [];
    for (let i = 0; i < profiles.length; i++) {
        let profile = profiles[i];
        // console.log(profile.name)
        let priority = profile.custom_model.priority;
        let conditions = priority.map((item: any) => item.if);
        const operators = ['<', '>', '<=', '>='];
        let maxWidth = null
        for (const condition of conditions) {
            if (condition.includes('max_width')) {
                const parts = condition.split('max_width');
                for (const part of parts) {
                    for (const operator of operators) {
                        if (part.includes(operator)) {
                            const value = part.split(operator)[1].trim().split(/\s+/)[0]
                            // console.log(value)
                            maxWidth = value;
                        }
                    }
                }
            }
        }
        maxWidthList.push({ name: profile.name, value: maxWidth })
    }
    return maxWidthList
}

export const getMaxWidthByName = (maxWidthList: any, name: string) => {
    let foundElement = maxWidthList.filter((item: any) => item.name == name)
    return foundElement && foundElement.length > 0 ? foundElement[0].value : null
}



/**
 * This layer highlights path segments that are above the elevation threshold set by the horizontal line in the
 * path details diagram.
 */
export  function usePathDetailsLayer(map: Map, pathDetails: PathDetailsStoreState) {
    useEffect(() => {
        removePathSegmentsLayer(map)
        addPathSegmentsLayer(map, pathDetails)
        getApi().info().then(
            profileData => {
                let routingProfileName = getQueryStore().state.routingProfile.name
                let limit = getMaxWidthByName(getMaxWidthList(profileData), routingProfileName)
                console.log('api info result  limit===============> ', limit, routingProfileName)
                localStorage.setItem('currentProfile', JSON.stringify({limit: limit, routingProfileName: routingProfileName}));
                addDefaultSegmentsLayer(map, getRouteStore().state, limit || 1)
            }
        )
        return () => {
            removePathSegmentsLayer(map)
        }
    }, [map, pathDetails])
    return
}



export function removePathSegmentsLayer(map: Map) {
    map.getLayers()
        .getArray()
        .filter(l => l.get(highlightedPathSegmentLayerKey))
        .forEach(l => map.removeLayer(l))
}

export const mergeMaxWidthData = (maxWidthData: any, roadClasses: any, widthByRoadClass: any, pointCoordinateData: any) => {
    let mergedData = []
    for (let i = 0; i < pointCoordinateData.length; i++) {
        mergedData.push({lng: pointCoordinateData[i][0], lat: pointCoordinateData[i][1], val: -1})
    }

    for (let i = 0; i < maxWidthData.length; i++) {
        let fromIdx = maxWidthData[i][0]; 
        let toIdx = maxWidthData[i][1];
        let val =  maxWidthData[i][2];
        val = val && val > 0 ? val : -1;
        for (let pointIdx = fromIdx; pointIdx <= toIdx; pointIdx++) {
            if (val > -1 && mergedData[pointIdx].val < 0) {
                mergedData[pointIdx].val = val
            }
        }
    }

    for (let i = 0; i < roadClasses.length; i++) {
        let fromIdx = roadClasses[i][0]; 
        let toIdx = roadClasses[i][1];
        let roadClass =  roadClasses[i][2];
        let val = widthByRoadClass[roadClass];
        val = val && val > 0 ? val : -1;
        for (let pointIdx = fromIdx; pointIdx <= toIdx; pointIdx++) {
            if (val > -1 && mergedData[pointIdx].val < 0) {
                mergedData[pointIdx].val = val
            }
        }
    }

    for (let i = 0; i < pointCoordinateData.length; i++) {
        if (mergedData[i].val < 0) {
            console.log('mergedData[i].val < 0', i)
        }
    }


    return mergedData
}

export let widthByRoadClass: any = {
    motorway: 22,
    trunk: 15,
    primary: 12,
    secondary: 10,
    tertiary: 8,
    residential: 6,
    service: 4,
    footway: 2,
    path: 1.5,
    cycleway: 3,
    track: 3,
    unclassified: 5,
    living_street: 5,
    pedestrian: 2,
    steps: 2
}

export function addDefaultSegmentsLayer(map: Map, route: any, limit: number) {
    const highlightedPathSegmentLayerKey = 'highlightedPathSegmentLayer'
    map.getLayers()
    .getArray()
    .filter(l => l.get(highlightedPathSegmentLayerKey))
    .forEach(l => map.removeLayer(l))

    let pointCoordinateData = route.selectedPath.points.coordinates
    let maxWidthData = route.selectedPath?.details?.max_width
    let roadClasses = route.selectedPath?.details?.road_class

    let mergedData = mergeMaxWidthData(maxWidthData, roadClasses, widthByRoadClass, pointCoordinateData);

    // console.log('mergedData=====================> ', mergedData)

    let segments: any = []
    let seg: any = []
    for (let pointIdx = 0; pointIdx < pointCoordinateData.length; pointIdx++) {
        let val = mergedData[pointIdx].val
        if (val < limit) {
            seg.push({lng: pointCoordinateData[pointIdx][0], lat: pointCoordinateData[pointIdx][1]})
        } else {
            if (seg.length > 0) {
                segments.push(seg)
            }
            seg = []
        }
    }
    if (seg.length > 0) {
        segments.push(seg)
        seg = []
    }

    const style = new Style({
        stroke: new Stroke({
            color: 'red',
            width: 6,
            lineCap: 'round',
            lineJoin: 'round',
        }),
    })

    const highlightedPathSegmentsLayer = new VectorLayer({
        source: new VectorSource({
            features: new GeoJSON().readFeatures(
                createHighlightedPathSegments(segments)
            ),
        }),
        style: () => style,
    })
    
    highlightedPathSegmentsLayer.set(highlightedPathSegmentLayerKey, true)
    highlightedPathSegmentsLayer.setZIndex(3)
    map.addLayer(highlightedPathSegmentsLayer)
}

// export function addDefaultSegmentsLayer(map: Map, route: any, limit: number) {
//     const highlightedPathSegmentLayerKey = 'highlightedPathSegmentLayer'

//     map.getLayers()
//     .getArray()
//     .filter(l => l.get(highlightedPathSegmentLayerKey))
//     .forEach(l => map.removeLayer(l))

//     let pointCoordinateData = route.selectedPath.points.coordinates
//     let maxWidthData = route.selectedPath?.details?.max_width

//     let segments: any = []
//     let lastVal = 0;
//     // let limit = 1;
//     for (let i = 0; i < maxWidthData.length; i++) {
//         let fromIdx = maxWidthData[i][0]; 
//         let toIdx = maxWidthData[i][1];
//         let val =  maxWidthData[i][2];
//         val = val && val > 0 ? val : lastVal;
//         lastVal = val;
//         let seg: any = []
//         for (let pointIdx = fromIdx; pointIdx <= toIdx; pointIdx++) {
//             if (val < limit) {
//                 seg.push({lng: pointCoordinateData[pointIdx][0], lat: pointCoordinateData[pointIdx][1]})
//             } else {
//                 if (seg.length > 0) {
//                     segments.push(seg)
//                 }
//                 seg = []
//             }
//         }
//         if (seg.length > 0) {
//             segments.push(seg)
//             seg = []
//         }
//     }

//     const style = new Style({
//         stroke: new Stroke({
//             color: 'red',
//             width: 4,
//             lineCap: 'round',
//             lineJoin: 'round',
//         }),
//     })
//     const highlightedPathSegmentsLayer = new VectorLayer({
//         source: new VectorSource({
//             features: new GeoJSON().readFeatures(
//                 createHighlightedPathSegments(segments)
//             ),
//         }),
//         style: () => style,
//     })
    
//     highlightedPathSegmentsLayer.set(highlightedPathSegmentLayerKey, true)
//     highlightedPathSegmentsLayer.setZIndex(3)
//     map.addLayer(highlightedPathSegmentsLayer)
// }

export function addPathSegmentsLayer(map: Map, pathDetails: PathDetailsStoreState) {
    const style = new Style({
        stroke: new Stroke({
            // todo
            color: 'red',
            width: 4,
            lineCap: 'round',
            lineJoin: 'round',
        }),
    })
    const highlightedPathSegmentsLayer = new VectorLayer({
        source: new VectorSource({
            features: new GeoJSON().readFeatures(
                createHighlightedPathSegments(pathDetails.pathDetailsHighlightedSegments)
            ),
        }),
        style: () => style,
    })
    highlightedPathSegmentsLayer.set(highlightedPathSegmentLayerKey, true)
    highlightedPathSegmentsLayer.setZIndex(3)
    map.addLayer(highlightedPathSegmentsLayer)
}

export function createHighlightedPathSegments(segments: Coordinate[][]) {
    const featureCollection: FeatureCollection = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                geometry: {
                    type: 'MultiLineString',
                    coordinates: segments.map(s => s.map(c => fromLonLat([c.lng, c.lat]))),
                },
                properties: {},
            },
        ],
    }
    return featureCollection
}
