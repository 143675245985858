import { createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PathDetails from '@/pathDetails/PathDetails'
import styles from './App.module.css'
import {
    getApiInfoStore,
    getErrorStore,
    getMapFeatureStore,
    getMapOptionsStore,
    getPathDetailsStore,
    getQueryStore,
    getRouteStore,
    getSettingsStore,
} from '@/stores/Stores'
import MapComponent from '@/map/MapComponent'
import MapOptions from '@/map/MapOptions'
import MobileSidebar from '@/sidebar/MobileSidebar'
import { useMediaQuery } from 'react-responsive'
import RoutingResults from '@/sidebar/RoutingResults'
import PoweredBy from '@/sidebar/PoweredBy'
import { Coordinate, QueryStoreState, RequestState } from '@/stores/QueryStore'
import { RouteStoreState } from '@/stores/RouteStore'
import { MapOptionsStoreState } from '@/stores/MapOptionsStore'
import { ErrorStoreState } from '@/stores/ErrorStore'
import Search from '@/sidebar/search/Search'
import ErrorMessage from '@/sidebar/ErrorMessage'
import useBackgroundLayer from '@/layers/UseBackgroundLayer'
import useQueryPointsLayer from '@/layers/UseQueryPointsLayer'
import usePathsLayer from '@/layers/UsePathsLayer'
import ContextMenu from '@/layers/ContextMenu'
import { addPathSegmentsLayer, removePathSegmentsLayer, usePathDetailsLayer } from '@/layers/UsePathDetailsLayer'
import { Map } from 'ol'
import { getMap } from '@/map/map'
import CustomModelBox from '@/sidebar/CustomModelBox'
import useRoutingGraphLayer from '@/layers/UseRoutingGraphLayer'
import useUrbanDensityLayer from '@/layers/UseUrbanDensityLayer'
import useMapBorderLayer from '@/layers/UseMapBorderLayer'
import RoutingProfiles from '@/sidebar/search/routingProfiles/RoutingProfiles'
import MapPopups from '@/map/MapPopups'
import Menu from '@/sidebar/menu.svg'
import Cross from '@/sidebar/times-solid.svg'
import PlainButton from '@/PlainButton'
import useAreasLayer from '@/layers/UseAreasLayer'
import useExternalMVTLayer from '@/layers/UseExternalMVTLayer'
import LocationButton from '@/map/LocationButton'
import { SettingsContext } from './contexts/SettingsContext'

export const POPUP_CONTAINER_ID = 'popup-container'
export const SIDEBAR_CONTENT_ID = 'sidebar-content'

import { Stroke, Style } from 'ol/style'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { GeoJSON } from 'ol/format'
import { FeatureCollection } from 'geojson'
import { fromLonLat, transform } from 'ol/proj'
// import { Map, View } from 'ol'
// import { Stroke, Style } from 'ol/style'
// import VectorLayer from 'ol/layer/Vector'
// import VectorSource from 'ol/source/Vector'

export default function App() {
    const [settings, setSettings] = useState(getSettingsStore().state)
    const [query, setQuery] = useState(getQueryStore().state)
    const [info, setInfo] = useState(getApiInfoStore().state)
    const [route, setRoute] = useState(getRouteStore().state)
    const [error, setError] = useState(getErrorStore().state)
    const [mapOptions, setMapOptions] = useState(getMapOptionsStore().state)
    const [pathDetails, setPathDetails] = useState(getPathDetailsStore().state)
    const [mapFeatures, setMapFeatures] = useState(getMapFeatureStore().state)

    const [isChangePoint, setIsChangePoint] = useState(false)

    const map = getMap()
    // useEffect(() => {
    // map.getView().setCenter(transform([139.76188476025342, 35.6749609994206], 'EPSG:4326', 'EPSG:3857'));
    // map.getView().setZoom(9);
    // }, [])
   
    useEffect(() => {
        const onSettingsChanged = () => setSettings(getSettingsStore().state)
        const onQueryChanged = () => {
            console.log('onQueryChanged ============================>>', getQueryStore().state)
            return setQuery(getQueryStore().state)
        }
        const onInfoChanged = () => setInfo(getApiInfoStore().state)
        const onRouteChanged = () => {
            console.log('onRouteChanged ============================>>', getRouteStore().state)
            return setRoute(getRouteStore().state)
        }
        const onErrorChanged = () => setError(getErrorStore().state)
        const onMapOptionsChanged = () => setMapOptions(getMapOptionsStore().state)
        const onPathDetailsChanged = () => setPathDetails(getPathDetailsStore().state)
        const onMapFeaturesChanged = () => setMapFeatures(getMapFeatureStore().state)

        getSettingsStore().register(onSettingsChanged)
        getQueryStore().register(onQueryChanged)
        getApiInfoStore().register(onInfoChanged)
        getRouteStore().register(onRouteChanged)
        getErrorStore().register(onErrorChanged)
        getMapOptionsStore().register(onMapOptionsChanged)
        getPathDetailsStore().register(onPathDetailsChanged)
        getMapFeatureStore().register(onMapFeaturesChanged)

        onQueryChanged()
        onInfoChanged()
        onRouteChanged()
        onErrorChanged()
        onMapOptionsChanged()
        onPathDetailsChanged()
        onMapFeaturesChanged()

        return () => {
            getSettingsStore().register(onSettingsChanged)
            getQueryStore().deregister(onQueryChanged)
            getApiInfoStore().deregister(onInfoChanged)
            getRouteStore().deregister(onRouteChanged)
            getErrorStore().deregister(onErrorChanged)
            getMapOptionsStore().deregister(onMapOptionsChanged)
            getPathDetailsStore().deregister(onPathDetailsChanged)
            getMapFeatureStore().deregister(onMapFeaturesChanged)
        }
    }, [])

    // our different map layers
    useBackgroundLayer(map, mapOptions.selectedStyle)
    useExternalMVTLayer(map, mapOptions.externalMVTEnabled)
    useMapBorderLayer(map, info.bbox)
    useAreasLayer(map, settings.drawAreasEnabled, query.customModelStr, query.customModelEnabled)
    useRoutingGraphLayer(map, mapOptions.routingGraphEnabled)
    useUrbanDensityLayer(map, mapOptions.urbanDensityEnabled)
    usePathsLayer(map, route.routingResult.paths, route.selectedPath, query.queryPoints)
    useQueryPointsLayer(map, query.queryPoints)
    usePathDetailsLayer(map, pathDetails)
    const isSmallScreen = useMediaQuery({ query: '(max-width: 44rem)' })
    
    function createHighlightedPathSegments(segments: Coordinate[][]) {
        const featureCollection: FeatureCollection = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'MultiLineString',
                        coordinates: segments.map(s => s.map(c => fromLonLat([c.lng, c.lat]))),
                    },
                    properties: {},
                },
            ],
        }
        return featureCollection
    }

    const myCallback = (limit: number) => {
        const highlightedPathSegmentLayerKey = 'highlightedPathSegmentLayer'
        map.getLayers()
        .getArray()
        .filter(l => l.get(highlightedPathSegmentLayerKey))
        .forEach(l => map.removeLayer(l))

        let pointCoordinateData = route.selectedPath.points.coordinates
        let maxWidthData = route.selectedPath?.details?.max_width

        let segments: any = []
        let lastVal = 0;
        for (let i = 0; i < maxWidthData.length; i++) {
            let fromIdx = maxWidthData[i][0]; 
            let toIdx = maxWidthData[i][1];
            let val =  maxWidthData[i][2];
            val = val && val > 0 ? val : lastVal;
            lastVal = val;
            let seg: any = []
            for (let pointIdx = fromIdx; pointIdx <= toIdx; pointIdx++) {
                if (val < limit) {
                    seg.push({lng: pointCoordinateData[pointIdx][0], lat: pointCoordinateData[pointIdx][1]})
                } else {
                    if (seg.length > 0) {
                        segments.push(seg)
                    }
                    seg = []
                }
            }
            if (seg.length > 0) {
                segments.push(seg)
                seg = []
            }
        }

        const style = new Style({
            stroke: new Stroke({
                color: 'red',
                width: 4,
                lineCap: 'round',
                lineJoin: 'round',
            }),
        })
        
        const highlightedPathSegmentsLayer = new VectorLayer({
            source: new VectorSource({
                features: new GeoJSON().readFeatures(
                    createHighlightedPathSegments(segments)
                ),
            }),
            style: () => style,
        })
        
        highlightedPathSegmentsLayer.set(highlightedPathSegmentLayerKey, true)
        highlightedPathSegmentsLayer.setZIndex(3)
        map.addLayer(highlightedPathSegmentsLayer)
    }

    const myCallbackColorByElevator = (limit: number) => {
        const highlightedPathSegmentLayerKey = 'highlightedPathSegmentLayer'

        map.getLayers()
        .getArray()
        .filter(l => l.get(highlightedPathSegmentLayerKey))
        .forEach(l => map.removeLayer(l))

        let PathDetailsData = route.selectedPath.points.coordinates
        const style = new Style({
            stroke: new Stroke({
                color: 'red',
                width: 4,
                lineCap: 'round',
                lineJoin: 'round',
            }),
        })
        let segments: any = []
        let seg: any = []
        for (let i = 0; i < PathDetailsData.length; i++) {
            if (PathDetailsData[i][2] > limit) {
                seg.push({lng: PathDetailsData[i][0], lat: PathDetailsData[i][1]})
            } else {
                if (seg.length > 0) {
                    segments.push(seg)
                }
                seg = []
            }
        }
        if (seg.length > 0) {
            segments.push(seg)
        }
        const highlightedPathSegmentsLayer = new VectorLayer({
            source: new VectorSource({
                features: new GeoJSON().readFeatures(
                    createHighlightedPathSegments(segments)
                ),
            }),
            style: () => style,
        })
        
        highlightedPathSegmentsLayer.set(highlightedPathSegmentLayerKey, true)
        highlightedPathSegmentsLayer.setZIndex(3)
        map.addLayer(highlightedPathSegmentsLayer)
    }

    const mapSetPointHandle = () => {
        setIsChangePoint(!isChangePoint)
    }

    return (
        <SettingsContext.Provider value={settings}>
            <div className={styles.appWrapper}>
                <MapPopups map={map} pathDetails={pathDetails} mapFeatures={mapFeatures} />
                <ContextMenu map={map} route={route} queryPoints={query.queryPoints} onSetPoint = {() => mapSetPointHandle()} />
                {isSmallScreen ? (
                    <SmallScreenLayout
                        query={query}
                        route={route}
                        map={map}
                        mapOptions={mapOptions}
                        error={error}
                        encodedValues={info.encoded_values}
                        drawAreas={settings.drawAreasEnabled}
                        myCallback = {myCallback}
                    />
                ) : (
                    <LargeScreenLayout
                        query={query}
                        route={route}
                        map={map}
                        mapOptions={mapOptions}
                        error={error}
                        encodedValues={info.encoded_values}
                        drawAreas={settings.drawAreasEnabled}
                        myCallback = {myCallback}
                        isChangePoint = {isChangePoint}
                    />
                )}
            </div>
        </SettingsContext.Provider>
    )
}

interface LayoutProps {
    query: QueryStoreState
    route: RouteStoreState
    map: Map
    mapOptions: MapOptionsStoreState
    error: ErrorStoreState
    encodedValues: object[]
    drawAreas: boolean,
    myCallback?: (input: number) => void
    isChangePoint?: boolean
}


// Define the ref type
export type LargeScreenLayoutRef = {
	initWidthLimit: () => void;
};

const  LargeScreenLayout =({ query, route, map, error, mapOptions, encodedValues, drawAreas, myCallback = (input: number) => {}, isChangePoint = false}: LayoutProps) => {
    const [showSidebar, setShowSidebar] = useState(true)
    const [showCustomModelBox, setShowCustomModelBox] = useState(false)

    const [widthLimit, setWidthLimit] = useState(0)

    const inputHanlde = (e: any) => {
        setWidthLimit(e.target.value)
        myCallback(parseInt(e.target.value))
    }

    useEffect(() => {
        setWidthLimit(0)
    }, [isChangePoint])
      
    return (
        <>
            {showSidebar ? (
                <div className={styles.sidebar}>
                    <div className={styles.sidebarContent} id={SIDEBAR_CONTENT_ID}>
                        <PlainButton onClick={() => setShowSidebar(false)} className={styles.sidebarCloseButton}>
                            <Cross />
                        </PlainButton>
                        <RoutingProfiles
                            routingProfiles={query.profiles}
                            selectedProfile={query.routingProfile}
                            showCustomModelBox={showCustomModelBox}
                            toggleCustomModelBox={
                                () => {
                                    setShowCustomModelBox(!showCustomModelBox)
                                }
                            }
                            customModelBoxEnabled={query.customModelEnabled}
                            onSelectProfile = {() => {
                                setWidthLimit(0)
                            }}
                        />
                        {showCustomModelBox && (
                            <CustomModelBox
                                customModelEnabled={query.customModelEnabled}
                                encodedValues={encodedValues}
                                customModelStr={query.customModelStr}
                                queryOngoing={query.currentRequest.subRequests[0]?.state === RequestState.SENT}
                                drawAreas={drawAreas}
                            />
                        )}

                        {/* <div style={{paddingLeft: '30px'}}>
                            <label>
                                Width Limit:  <input value={widthLimit} disabled={route.routingResult?.paths?.length > 0 ? false : true} style={{paddingLeft: '30px'}} type='number' min="1" max="100" id="elevator-limit" name="my-limit" onChange={inputHanlde}/>
                            </label>
                        </div> */}

                        <Search points={query.queryPoints} />

                        <div>{!error.isDismissed && <ErrorMessage error={error} />}</div>
                        <RoutingResults
                            info={route.routingResult.info}
                            paths={route.routingResult.paths}
                            selectedPath={route.selectedPath}
                            currentRequest={query.currentRequest}
                            profile={query.routingProfile.name}
                        />
                        {/* <div>
                            <PoweredBy />
                        </div> */}
                    </div>
                </div>
            ) : (
                <div className={styles.sidebarWhenClosed} onClick={() => setShowSidebar(true)}>
                    <PlainButton className={styles.sidebarOpenButton}>
                        <Menu />
                    </PlainButton>
                </div>
            )}
            <div className={styles.popupContainer} id={POPUP_CONTAINER_ID} />
            <div className={styles.onMapRightSide}>
                <MapOptions {...mapOptions} />
                <LocationButton queryPoints={query.queryPoints} />
            </div>
            <div className={styles.map}>
                <MapComponent map={map} />
            </div>

            <div className={styles.pathDetails}>
                <PathDetails selectedPath={route.selectedPath} />
            </div>
        </>
    )
}

function SmallScreenLayout({ query, route, map, error, mapOptions, encodedValues, drawAreas, myCallback = (input: number) => {}  }: LayoutProps) {
    return (
        <>
            <div className={styles.smallScreenSidebar}>
                <MobileSidebar
                    query={query}
                    route={route}
                    error={error}
                    encodedValues={encodedValues}
                    drawAreas={drawAreas}
                    myCallback = {myCallback}
                />
            </div>
            <div className={styles.smallScreenMap}>
                <MapComponent map={map} />
            </div>
            <div className={styles.smallScreenMapOptions}>
                <div className={styles.onMapRightSide}>
                    <MapOptions {...mapOptions} />
                    <LocationButton queryPoints={query.queryPoints} />
                </div>
            </div>

            <div className={styles.smallScreenRoutingResult}>
                <RoutingResults
                    info={route.routingResult.info}
                    paths={route.routingResult.paths}
                    selectedPath={route.selectedPath}
                    currentRequest={query.currentRequest}
                    profile={query.routingProfile.name}
                />
            </div>

            {/* <div className={styles.smallScreenPoweredBy}>
                <PoweredBy />
            </div> */}
        </>
    )
}
