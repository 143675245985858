// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-sidebar-ErrorMessage-module__errorMessageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #f97777;
    color: white;
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.3rem;
}

.src-sidebar-ErrorMessage-module__errorMessage {
    font-size: smaller;
    word-break: break-word;
    max-width: 90%;
    user-select: all;
}

.src-sidebar-ErrorMessage-module__errorMessageCloseBtn {
    width: 0.8rem;
    color: white;
    align-self: flex-start;
    flex-shrink: 0;
}

.src-sidebar-ErrorMessage-module__errorMessageCloseBtn svg {
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/sidebar/ErrorMessage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,yBAAyB;IACzB,YAAY;IACZ,cAAc;IACd,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".errorMessageContainer {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    background-color: #f97777;\r\n    color: white;\r\n    margin: 0.5rem;\r\n    padding: 0.5rem;\r\n    border-radius: 0.3rem;\r\n}\r\n\r\n.errorMessage {\r\n    font-size: smaller;\r\n    word-break: break-word;\r\n    max-width: 90%;\r\n    user-select: all;\r\n}\r\n\r\n.errorMessageCloseBtn {\r\n    width: 0.8rem;\r\n    color: white;\r\n    align-self: flex-start;\r\n    flex-shrink: 0;\r\n}\r\n\r\n.errorMessageCloseBtn svg {\r\n    width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorMessageContainer": `src-sidebar-ErrorMessage-module__errorMessageContainer`,
	"errorMessage": `src-sidebar-ErrorMessage-module__errorMessage`,
	"errorMessageCloseBtn": `src-sidebar-ErrorMessage-module__errorMessageCloseBtn`
};
export default ___CSS_LOADER_EXPORT___;
