import styles from '@/sidebar/instructions/Instructions.module.css'

import uTurn from './u_turn.png'
import uTurnLeft from './u_turn_left.png'
import keepLeft from './keep_left.png'
import sharpLeft from './sharp_left.png'
import left from './left.png'
import slightLeft from './slight_left.png'
import continueImg from './continue.png'
import slightRight from './slight_right.png'
import right from './right.png'
import sharpRight from './sharp_right.png'
import roundabout from './roundabout.png'
import keepRight from './keep_right.png'
import uTurnRight from './u_turn_right.png'
import ptStartTrip from './pt_start_trip.png'
import ptTransferTo from './pt_transfer_to.png'
import ptEndTrip from './pt_end_trip.png'
import { metersToText } from '@/Converters'
import { Instruction } from '@/api/graphhopper'
import { MarkerComponent } from '@/map/Marker'
import QueryStore, { QueryPointType } from '@/stores/QueryStore'
import Dispatcher from '@/stores/Dispatcher'
import { InstructionClicked } from '@/actions/Actions'
import { useContext, useEffect, useState } from 'react'
import { SettingsContext } from '@/contexts/SettingsContext'
import { getQueryStore, getRouteStore } from '@/stores/Stores'
import { getMaxWidthByName, getMaxWidthList, mergeMaxWidthData, widthByRoadClass } from '@/layers/UsePathDetailsLayer'
import { getApi } from '@/api/Api'
import { set } from 'ol/transform'

export default function (props: { instructions: Instruction[]; us: boolean }) {
    // console.log('getRouteStore().state ==>> ', getRouteStore().state)
    // console.log('instruction ==>> ', props.instructions)

    const [limit, setLimit] = useState(0)
    const [warning, setWarning] = useState([...props.instructions])
    // const [instructionData, setInstructionData] = useState(props.instructions)

    let instructionData = props.instructions;
    let route = getRouteStore().state
    let pointCoordinateData = route.selectedPath.points.coordinates
    let maxWidthData = route.selectedPath?.details?.max_width
    let roadClasses = route.selectedPath?.details?.road_class

    let mergedData = mergeMaxWidthData(maxWidthData, roadClasses, widthByRoadClass, pointCoordinateData);
    // console.log('mergedData=====================> ', mergedData)
    
    
    // // const getInfo = async() => {

    useEffect(() => {
       

        (async function() {
            try{
                let tmpLimit: number = 0
                await getApi().info().then(
                    profileData => {
                        let routingProfileName = getQueryStore().state.routingProfile.name
                        tmpLimit = getMaxWidthByName(getMaxWidthList(profileData), routingProfileName)
                        setLimit(tmpLimit)
                    }
                )

                let tmp = [...instructionData]
                for (let i = 0; i < instructionData.length; i++) {
                    let instruction = instructionData[i];
                    let interval = instruction.interval
                    const startIdx = interval[0]
                    const endIdx = interval[1]
                    let foundSmallWidth = false
                    for (let pointIdx  = startIdx; pointIdx < endIdx; pointIdx++) {
                        if (mergedData[pointIdx].val < tmpLimit) {
                            foundSmallWidth = true
                            break
                        } 
                    }

                    if (foundSmallWidth) {
                        tmp[i].warning = `road width < ${tmpLimit}m`
                    } else {
                        tmp[i].warning = ''
                    }
                }
                setWarning(tmp)
            }
            catch(err){
                // setError(err)
            }finally{
                // setLoading(false)
            }
        }
        )()

    },[props])
    
    return (
        <ul className={styles.instructionsList}>
            <p>width limit: {limit|| ''}</p>
            {/* {props.instructions.map((instruction, i) => ( */}
            {instructionData.map((instruction, i) => (
                <div style={{display: 'flex'}} key={i}>
                    <Line key={i+100000} instruction={instruction} index={i} us={props.us} />
                    <p style={{ color: 'red', fontWeight: 'bold', paddingLeft: '30px'}}>{(warning[i] || {}).warning}</p>
                </div>
            ))}
        </ul>
    )
}

const Line = function ({ instruction, index, us }: { instruction: Instruction; index: number; us: boolean }) {
    const settings = useContext(SettingsContext)
    return (
        <li
            className={styles.instruction}
            onClick={() =>
                Dispatcher.dispatch(
                    new InstructionClicked(
                        { lng: instruction.points[0][0], lat: instruction.points[0][1] },
                        instruction.text
                    )
                )
            }
        >
            {getTurnSign(instruction.sign, index)}
            <span className={styles.mainText}>{instruction.text}</span>
            {instruction.motorway_junction && (
                <span style={{ background: us ? '#00674c' : '#003399' }} className={styles.motorwayJunction}>
                    {instruction.motorway_junction}
                </span>
            )}
            <span className={styles.distance}>{metersToText(instruction.distance, settings.showDistanceInMiles)}</span>
        </li>
    )
}

function getTurnSign(sign: number, index: number) {
    // from, via and to signs are special
    if (index === 0 || sign === 4 || sign === 5) {
        let markerColor
        if (index === 0) {
            markerColor = QueryStore.getMarkerColor(QueryPointType.From)
        } else if (sign === 4) {
            markerColor = QueryStore.getMarkerColor(QueryPointType.To)
        } else {
            markerColor = QueryStore.getMarkerColor(QueryPointType.Via)
        }

        return (
            <div className={styles.sign}>
                <MarkerComponent color={markerColor} />
            </div>
        )
    }
    return <img className={styles.sign} src={getSignName(sign)} alt={'turn instruction'} />
}

function getSignName(sign: number) {
    switch (sign) {
        case -98:
            return uTurn
        case -8:
            return uTurnLeft
        case -7:
            return keepLeft
        case -3:
            return sharpLeft
        case -2:
            return left
        case -1:
            return slightLeft
        case 0:
            return continueImg
        case 1:
            return slightRight
        case 2:
            return right
        case 3:
            return sharpRight
        case 6:
            return roundabout
        case 7:
            return keepRight
        case 8:
            return uTurnRight
        case 101:
            return ptStartTrip
        case 102:
            return ptTransferTo
        case 103:
            return ptEndTrip
        default:
            return 'unknown'
    }
}
