// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-sidebar-instructions-Instructions-module__instructionsList {
}

.src-sidebar-instructions-Instructions-module__instruction {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 3rem auto;
    align-items: center;
    cursor: pointer;
}

.src-sidebar-instructions-Instructions-module__sign {
    grid-row: 1 / span 2;
    justify-self: center;
    align-self: center;
    height: 1.5rem;
}

.src-sidebar-instructions-Instructions-module__sign > svg {
    width: 100%;
    height: 100%;
    display: block;
}

.src-sidebar-instructions-Instructions-module__mainText {
    margin-top: 1rem;
}

.src-sidebar-instructions-Instructions-module__motorwayJunction {
    font-weight: bold;
    font-size: smaller;
    color: white;
    width: fit-content;
    margin: 3px 0;
    padding: 5px;
    border-radius: 3px;
}

.src-sidebar-instructions-Instructions-module__distance {
    grid-column: 2 / span 1;
    font-size: small;
    color: #5b616a;
    padding-bottom: 1rem;
    border-bottom: 1px solid lightgray;
}
`, "",{"version":3,"sources":["webpack://./src/sidebar/instructions/Instructions.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,gCAAgC;IAChC,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,cAAc;IACd,oBAAoB;IACpB,kCAAkC;AACtC","sourcesContent":[".instructionsList {\r\n}\r\n\r\n.instruction {\r\n    display: grid;\r\n    grid-template-rows: auto auto;\r\n    grid-template-columns: 3rem auto;\r\n    align-items: center;\r\n    cursor: pointer;\r\n}\r\n\r\n.sign {\r\n    grid-row: 1 / span 2;\r\n    justify-self: center;\r\n    align-self: center;\r\n    height: 1.5rem;\r\n}\r\n\r\n.sign > svg {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: block;\r\n}\r\n\r\n.mainText {\r\n    margin-top: 1rem;\r\n}\r\n\r\n.motorwayJunction {\r\n    font-weight: bold;\r\n    font-size: smaller;\r\n    color: white;\r\n    width: fit-content;\r\n    margin: 3px 0;\r\n    padding: 5px;\r\n    border-radius: 3px;\r\n}\r\n\r\n.distance {\r\n    grid-column: 2 / span 1;\r\n    font-size: small;\r\n    color: #5b616a;\r\n    padding-bottom: 1rem;\r\n    border-bottom: 1px solid lightgray;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"instructionsList": `src-sidebar-instructions-Instructions-module__instructionsList`,
	"instruction": `src-sidebar-instructions-Instructions-module__instruction`,
	"sign": `src-sidebar-instructions-Instructions-module__sign`,
	"mainText": `src-sidebar-instructions-Instructions-module__mainText`,
	"motorwayJunction": `src-sidebar-instructions-Instructions-module__motorwayJunction`,
	"distance": `src-sidebar-instructions-Instructions-module__distance`
};
export default ___CSS_LOADER_EXPORT___;
